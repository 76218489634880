import React from 'react'
import PaymentError from 'src/components/paymentError.js'
import { GuardWrapper } from 'src/layouts/GuardWrapper'
import UserLayout from 'src/layouts/UserLayout'
function PaymentErrorPage(props) {
  return (
    <GuardWrapper {...props}>
      <div>
        <PaymentError />
      </div>
    </GuardWrapper>
  )
}
PaymentErrorPage.defaultProps = {
  getLayout: page => <UserLayout>{page}</UserLayout>,
  guestGuard: false,
  authGuard: false
}

export default PaymentErrorPage
