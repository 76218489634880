const { createSlice } = require('@reduxjs/toolkit')
const { getUserCourses } = require('./userCourse.action')

const initialState = {
  isUserCoursesGetting: false,
  isUserCoursesFetched: false,
  isUserCoursesFailedToFailed: false,
  UserCourses: { courseStatus: [] }
}

const userCourses = createSlice({
  name: 'userCourses',
  initialState: initialState,
  extraReducers: {
    [getUserCourses.pending]: state => {
      state.isUserCoursesGetting = true
    },
    [getUserCourses.fulfilled]: (state, action) => {
      state.isUserCoursesGetting = false
      state.isUserCoursesFetched = true
      state.UserCourses = action.payload
    },
    [getUserCourses.rejected]: state => {
      state.isUserCoursesGetting = false
      state.isUserCoursesFetched = false
      state.isUserCoursesFailedToFailed = true
    }
  }
})

export default userCourses.reducer
