import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='mt-40 mb-10 flex flex-col-reverse gap-5 items-center tablet:flex-row tablet:justify-between tablet:px-10 '>
      <p> Sanspareil Copyright &copy; {new Date().getFullYear()}</p>
      <div className='flex justify-start gap-5 text-sm font-medium '>
        <Link to='/privacy-policy' className=''>
          Privacy Policy
        </Link>
        <Link to='/terms-and-conditions' className=''>
          Terms and Conditions
        </Link>
        <Link to='/help' className=''>
          Help
        </Link>
      </div>
    </div>
  )
}
