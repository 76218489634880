const { useNavigate } = require('react-router-dom')
const { useAuth } = require('src/hooks/useAuth')

export function ProtectedRoute({ element }) {
  const { user } = useAuth()

  const isSubcribed = user?.payments[0]?.subscription

  const navigate = useNavigate()

  if (!isSubcribed) {
    navigate(-1)
    return null
  }

  return element
}
