import { Suspense, useEffect } from 'react'
import AOS from 'aos'
import Footer from 'src/components/footer'
import Spinner from 'src/components/spinner'
import { AuthProvider } from 'src/context/AuthContext'

export const SuspenseLayout = ({ children }) => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <AuthProvider>
      <div className='flex flex-col min-h-screen'>
        <div className='flex-1'>
          <Suspense fallback={<Spinner />}>{children}</Suspense>
        </div>
        <Footer />
      </div>
    </AuthProvider>
  )
}
