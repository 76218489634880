import { combineReducers } from '@reduxjs/toolkit'

import getAllCoursesSlicer from './addText/getAllCourses.slicer'
import updateProfilePicSlicer from 'src/redux/profilepic/profilePic.slicer'
import updateUserDataSlicer from 'src/redux/userDataUpdate/userData.Slicer'
import CreatorData from 'src/redux/creatorInfo/creator.Slicer'
import paymentSlicer from './payment/payment.Slicer'
import userCourses from './userCourses/userCourse.Slicer'
import User from './userData/getUserData.Slicer'
import Catogeries from './filters/filtersSlicer'
// import helpSlicer from './help/help.slicer'
const rootReducer = combineReducers({
  getAllCoursesSlicer: getAllCoursesSlicer,
  updateProfilePic: updateProfilePicSlicer,
  updateUserData: updateUserDataSlicer,
  payment: paymentSlicer,
  CreatorData,
  userCourses,
  User,
  filters: Catogeries
  // help: helpSlicer
})

export default rootReducer
