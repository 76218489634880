import { createSlice } from '@reduxjs/toolkit'
import { getAllCategories } from './filterActions'

const initialState = {
  status: '',
  category: null,
  filtersData: [],
  selectedFilter: []
}

const getCategories = createSlice({
  name: 'getCategories',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.category = action.payload
    },
    addSelectedFilter: (state, action) => {
      // Destructure the key and value from the payload
      const [key, value] = Object.entries(action.payload)[0]

      // Check if an object with the same key-value pair already exists in the selectedFilter array
      const isAlreadySelected = state.selectedFilter.some(filter => filter[key] === value)

      // If it's not already selected, add it to the array
      if (!isAlreadySelected) {
        state.selectedFilter = [...state.selectedFilter, action.payload]
      }
    },
    removeFilter: (state, action) => {
      state.selectedFilter = []
    }
  },
  extraReducers: builder => {
    builder.addCase(getAllCategories.pending, state => {
      state.status = 'pending'
    })
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.status = 'fulfilled'
      state.filtersData = action.payload
    })
    builder.addCase(getAllCategories.rejected, state => {
      state.status = 'rejected'
    })
  }
})

export const { addCategory, addSelectedFilter, removeFilter } = getCategories.actions

export default getCategories.reducer
