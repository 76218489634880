const { createSlice } = require('@reduxjs/toolkit')
const { getCreator } = require('./creator.action')

const initialState = {
  iscreatorUpdating: false,
  iscreatorUpdated: false,
  iscreatorFailedToUpdate: false,
  creator: []
}

const CreatorDataSlicer = createSlice({
  name: 'Creator',
  initialState: initialState,
  extraReducers: {
    [getCreator.pending]: state => {
      state.iscreatorUpdating = true
    },
    [getCreator.fulfilled]: (state, action) => {
      state.iscreatorUpdating = false
      state.iscreatorUpdated = true
      state.creator = action.payload
    },
    [getCreator.rejected]: state => {
      state.iscreatorUpdating = false
      state.iscreatorUpdated = false
      state.iscreatorFailedToUpdate = true
    }
  }
})

export default CreatorDataSlicer.reducer
