// import ReactPlayer from 'react-player'
// import { GuardWrapper } from 'src/layouts/GuardWrapper'

// const { useParams } = require('react-router-dom')

// const ShowSingleVideo = props => {
//   const { id, title } = useParams()

//   return (
//     <GuardWrapper {...props}>
//       <div className='pl-10 pr-10  tablet:pl-20 tablet:pr-10'>
//         <h3 className='text-5xl my-20 font-bold'>{title}</h3>
//         <ReactPlayer
//           url={`https://player.vimeo.com/video/${id}?h=8df4149e14.json`}
//           controls
//           style={{ minWidth: '100%' }}
//         />
//       </div>
//     </GuardWrapper>
//   )
// }
// export default ShowSingleVideo

import ReactPlayer from 'react-player'
import { GuardWrapper } from 'src/layouts/GuardWrapper'

const { useParams } = require('react-router-dom')

const ShowSingleVideo = props => {
  const { id, title } = useParams()

  return (
    <GuardWrapper {...props}>
      <div>
        <div className='px-10 tablet:px-20 pt-8'>
          {' '}
          <h3 className='text-2xl tablet:text-5xl mt-8 tablet:text-center font-bold px-8'>{title}</h3>
        </div>
        <div className='w-full h-[400px] tablet:h-[600px]  target:m-auto mt-5 px-8'>
          <ReactPlayer
            url={`https://player.vimeo.com/video/${id}?h=8df4149e14.json`}
            controls
            width='100%'
            height='100%'
          />
        </div>
      </div>
    </GuardWrapper>
  )
}

export default ShowSingleVideo
