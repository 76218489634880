import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateProfilePictureAPI } from './api'
import toast from 'react-hot-toast'

export const updateProfilePic = createAsyncThunk('deleteSocialMedia/deleteSocialMedia', async payload => {
  const resultPromise = new Promise((resolve, reject) => {
    updateProfilePictureAPI(payload)
      .then(res => {
        resolve(res.data)
      })
      .catch(({ response }) => {
        reject(response.data?.error || 'Something went wrong')
      })
  })

  toast.promise(resultPromise, {
    loading: 'Profile picture updating',
    success: 'Updated Successfully',
    error: err => err
  })

  return await resultPromise
})
