import { axiosClient } from 'src/configs/axios'

export const updateProfilePictureAPI = async payload => {
  const res = await axiosClient.post('/profile/client/update-picture', payload, {
    params: {
      imageType: 'display'
    }
  })
  return res
}
