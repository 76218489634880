import { lazy } from 'react'
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { ErrorBoundary } from 'src/components/errorBoundary'
import { SuspenseLayout } from 'src/layouts/SuspenseLayout'
import ThankYouPaypal from 'src/pages/PaypalSuccess'
import ClassSchedule from 'src/pages/classShedule'
import { ProtectedRoute } from 'src/pages/courseDetail/protected'
import ForgotPassword from 'src/pages/forgetPassword'
import PaymentErrorPage from 'src/pages/paymentError'
import ShowSingleVideo from 'src/pages/showSingleVideo'

const Home = lazy(() => import('src/pages/home'))
const Landing = lazy(() => import('src/pages/landing'))
const CreatorProfile = lazy(() => import('src/pages/creatorprofile'))
const PrivacyPolicy = lazy(() => import('src/pages/privacyPolicy'))
const CourseDetail = lazy(() => import('src/pages/courseDetail'))
const TermsAndConditions = lazy(() => import('src/pages/terms&Conditions'))
const Courses = lazy(() => import('src/pages/courses'))
const HelpPage = lazy(() => import('src/pages/help'))
const Login = lazy(() => import('src/pages/login'))
const Videos = lazy(() => import('src/pages/videos'))
const ProfileSettings = lazy(() => import('src/pages/profileSettings'))
const Register = lazy(() => import('src/pages/register'))
const ThankYou = lazy(() => import('src/pages/thanks'))
const UserProfilePage = lazy(() => import('src/pages/userProfile'))
const ResetPassword = lazy(() => import('src/pages/resetPassword'))
export const AppRouter = () => {
  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route
            errorElement={<ErrorBoundary />}
            element={
              <SuspenseLayout>
                <Outlet />
              </SuspenseLayout>
            }
          >
            <Route element={<Landing />} path='/' />
            <Route element={<Login />} path='/login' />
            <Route element={<Register />} path='/register' />

            <Route element={<Home />} path='/home' />
            <Route path='/course-view/:id' element={<ProtectedRoute element={<CourseDetail />} />} />
            <Route element={<Videos />} path='/videos' />
            <Route element={<Courses />} path='/courses' />
            <Route element={<UserProfilePage />} path='/userprofile' />
            <Route element={<PrivacyPolicy />} path='/privacy-policy' />
            <Route element={<TermsAndConditions />} path='/terms-and-conditions' />
            <Route element={<CreatorProfile />} path='/authorprofile/:_id' />
            {/* <Route element={<ProfileSettings />} path='/settings' /> */}
            <Route element={<HelpPage />} path='/help' />
            <Route element={<ProtectedRoute element={<ShowSingleVideo />} />} path='/video/:id/:title' />
            <Route element={<ThankYou />} path='/thanks/:sessionId' />
            <Route element={<ThankYouPaypal />} path='/thanks-paypal/:subscriptionId' />
            <Route element={<PaymentErrorPage />} path='/payment-incomplete' />
            <Route element={<ClassSchedule />} path='/classSchedule' />
            <Route element={<ForgotPassword />} path='/forget-password' />
            <Route element={<ResetPassword />} path='/reset-password/:token' />
          </Route>
        )
      )}
    />
  )
}
