const { createSlice } = require('@reduxjs/toolkit')
const { getAllCourses } = require('./getAllCourses.action')

const initialState = {
  isCoursesAdding: false,
  isCoursesAdded: false,
  isCoursesFailedToAdd: false,
  data: []
}

const getAllCoursesSlicer = createSlice({
  name: 'getAllCoursesSlicer',
  initialState: initialState,
  extraReducers: {
    [getAllCourses.pending]: state => {
      state.isCoursesAdding = true
    },
    [getAllCourses.fulfilled]: state => {
      state.isCoursesAdding = false
      state.isCoursesAdded = true
    },
    [getAllCourses.rejected]: state => {
      state.isCoursesAdding = false
      state.isCoursesAdded = false
      state.isCoursesFailedToAdd = true
    }
  }
})
export default getAllCoursesSlicer.reducer
