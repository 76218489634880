import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Icon } from '@iconify/react'
import logo from 'src/assets/images/sans.png'
import { useAuth } from 'src/hooks/useAuth'
import axios from 'axios'
import profile from 'src/assets/images/len.jpg'
import { ranks } from '../aboutAuthorProfile/data'

import { useSelector } from 'react-redux'
const navigation = [
  { name: 'Home', iconname: 'material-symbols:home', to: '/home', current: false },
  { name: 'Videos', iconname: 'ri:video-fill', to: '/videos', current: false },
  { name: 'Courses', iconname: 'ion:copy', to: '/courses', current: false },
  { name: 'Class Schedule', iconname: 'streamline:class-lesson-solid', to: '/classSchedule', current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  const { user } = useAuth()
  const { displayImg } = useSelector(state => state.updateProfilePic)
  const { rank } = useSelector(state => state.User.user)
  const imgClient = axios.create({
    imgBaseURL: process.env.REACT_APP_HTTP_IMAGE
  })

  const [imageUrl, setImageUrl] = useState(`${imgClient.defaults.imgBaseURL}/${user && user.displayImg}`)

  const profileImg = imageUrl ? imageUrl : profile

  const auth = useAuth()

  useEffect(() => {
    if (displayImg) {
      setImageUrl(`${imgClient.defaults.imgBaseURL}/${displayImg}`)
    }
  }, [displayImg])

  return (
    <Disclosure as='nav' className='bg-black'>
      {({ open }) => (
        <>
          <div className='mx-auto w-full px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='ml-10  tablet:ml-0'>
                <img className='h-14 w-10 py-2' src={logo} alt='Your Company' />
              </div>

              <div className='hidden sm:ml-6 sm:block'>
                <div className='flex gap-10  space-x-4'>
                  {navigation.map(({ iconname, name, to }) => (
                    <div className='flex  items-center text-xl hover:text-green-500'>
                      <Icon icon={iconname} className='text-2xl' />

                      <Link
                        key={name}
                        to={to}
                        className={classNames('text-gray-300 px-1 py-2 text-sm font-medium hover:text-green-500')}
                      >
                        {name}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>

              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                {/* <button
                  type='button'
                  className='relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                >
                  <Icon icon='mdi:bell' className='h-6 w-6 text-white' aria-hidden='true' />
                </button> */}
                {!rank ? (
                  <div className='min-h-screen flex-col gap-2 flex items-center justify-center'>
                    <Icon className='w-8 h-8 animate-spin' icon='fluent:spinner-ios-16-filled' />
                  </div>
                ) : (
                  <img src={ranks[rank]} alt='rank' className='max-w-[50px] max-h-[50px]' />
                )}

                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='absolute -inset-1.5' />

                      {/* <img className='h-8 w-8 rounded-full' src={user?.displayImg ? profileImg : gamer} alt='' /> */}
                      {!user ? (
                        <div className='min-h-screen flex-col gap-2 flex items-center justify-center'>
                          <Icon className='w-8 h-8 animate-spin' icon='fluent:spinner-ios-16-filled' />
                        </div>
                      ) : (
                        <>
                          {user?.displayImg ? (
                            <img className='h-8 w-8 rounded-full' src={profileImg} alt='' />
                          ) : (
                            <Icon icon='iconamoon:profile-fill' className='text-3xl text-[#79797dff] p-1' />
                          )}
                        </>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-alpha py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to='/userprofile'
                            className={classNames(active ? 'bg-gray-600' : '', 'block px-4 py-3 text-sm text-white ')}
                          >
                            Your Profile
                          </Link>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        {({ active }) => (
                          <Link
                            to='/settings'
                            className={classNames(active ? 'bg-gray-600' : '', 'block px-4 py-2 text-sm text-white ')}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item> */}

                      <Menu.Item className=' hover:text-green-500'>
                        <div className='border-t-2 border-gray-500 '>
                          <button
                            onClick={auth.logout}
                            className='flex gap-2 items-center px-4 text-white hover:text-green-500 hover:cursor-pointer'
                          >
                            <Icon icon='material-symbols:logout' className='text-2xl' />
                            <span className={classNames('block py-3  text-sm')}>Sign out</span>
                          </button>
                        </div>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='absolute -inset-0.5' />

                  {open ? (
                    <Icon icon='mdi:hamburger-menu' className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Icon icon='mdi:hamburger-menu' className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-x-1 px-2 pb-3 pt-2'>
              {navigation.map(({ name, to, current }) => (
                <Disclosure.Button
                  key={name}
                  as={Link}
                  to={to}
                  className={classNames(
                    current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-green-400',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={current ? 'page' : undefined}
                >
                  {name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
