import { Icon } from '@iconify/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import BlankLayout from 'src/layouts/BlankLayout'
import { GuardWrapper } from 'src/layouts/GuardWrapper'

function ClassSchedule(props) {
  useEffect(() => {
    document.title = `Class Schedule • Sanspareil`
  }, [])
  const navigate = useNavigate()
  return (
    <GuardWrapper {...props}>
      <div className='px-8 mt-20'>
        {' '}
        <Icon
          icon='ep:back'
          onClick={() => navigate(-1)}
          className='text-white cursor-pointer w-[30px] h-[30px] mt-10'
        />
      </div>
      <div className=' flex flex-col w-full px-16 tablet:w-4/12 m-auto justify-center'>
        <div className='text-center'>
          <h1 className=' text-2xl font-semibold'>CLASS SCHEDULE</h1>
        </div>

        <div className='bg-white text-black py-2 rounded-lg text-center mt-10 px-4'>
          <p>
            Sessions shown in time zone: <strong>Eastern Time – New York</strong>
          </p>
        </div>
        <div className='mt-10'>
          <p>FOR ALL RANKS</p>
        </div>

        <div className='flex justify-between pt-10'>
          <div className='flex flex-col space-y-8'>
            <h1 className='font-bold text-2xl underline  underline-offset-8 '>Days</h1>

            <p>MONDAY - FRIDAY</p>
            <p>MONDAY - FRIDAY</p>
            <p>MONDAY - FRIDAY</p>
            <p>SATURDAY</p>
          </div>
          <div className='flex flex-col space-y-8'>
            <p className='font-bold text-2xl underline underline-offset-8'>Time</p>

            <p>5:00 AM </p>
            <p>3:00 PM</p>
            <p>9:30 PM</p>
            <p>5:00 AM </p>
          </div>
        </div>
      </div>
    </GuardWrapper>
  )
}
ClassSchedule.defaultProps = {
  getLayout: page => <BlankLayout>{page}</BlankLayout>,
  guestGuard: false,
  authGuard: false
}
export default ClassSchedule
