import { updateUserEmail, updateUserPassword } from './upateData.action'

const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  isUserEmailUpdating: false,
  isUserEmailUpdated: false,
  isUserEmailFailedToUpdate: false,
  isUserpasswordUpdating: false,
  isUserpasswordUpated: false,
  isUserpasswordFailedToUpdate: false
}

const updateUserDataSlicer = createSlice({
  name: 'updatesUserEmail',
  initialState: initialState,
  extraReducers: {
    [updateUserEmail.pending]: state => {
      state.isUserEmailUpdating = true
    },
    [updateUserEmail.fulfilled]: state => {
      state.isUserEmailUpdating = false
      state.isUserEmailUpdated = true
    },
    [updateUserEmail.rejected]: state => {
      state.isUserEmailUpdating = false
      state.isUserEmailUpdated = false
      state.isUserEmailFailedToUpdate = true
    },
    ///password
    [updateUserPassword.pending]: state => {
      state.isUserpasswordUpdating = true
    },
    [updateUserPassword.fulfilled]: state => {
      state.isUserpasswordUpdating = false
      state.isUserpasswordUpated = true
    },
    [updateUserPassword.rejected]: state => {
      state.isUserpasswordUpdating = false
      state.isUserpasswordUpated = false
      state.isUserpasswordFailedToUpdate = true
    }
  }
})

export default updateUserDataSlicer.reducer
