import { axiosClient } from 'src/configs/axios'

export const getAllCoursesApi = async paylaod => {
  const params = {}
  params.title = paylaod[0].title

  paylaod.forEach(({ key, value }) => {
    params[key] = value
  })

  const res = await axiosClient.get('/courses/client/', {
    params
  })

  return res.data
}


export const getSingleCourse = async paylaod => {
  const res = await axiosClient.get(`/courses/client/${paylaod}`)

  return res.data
}
export const getSingleCoursePercentage = async paylaod => {
  const res = await axiosClient.get(`/progress/${paylaod}`)

  return res.data
}

export const getCoursesWithFilter = async (filterArray = []) => {
  try {
    // Transform the array of filter objects into a single object with arrays for each key
    const filters = filterArray.reduce((acc, filter) => {
      const key = Object.keys(filter)[0];
      const value = filter[key];
      if (acc[key]) {
        acc[key].push(value);
      } else {
        acc[key] = [value];
      }
      return acc;
    }, {});

    // Check if there's a searchTerm filter
    if (filters.title && filters.title.length === 0) {
      delete filters.title; // Remove empty search term if it exists
    }

    // Build the query string manually
    const queryString = Object.keys(filters)
      .map(key => {
        const values = filters[key].join(','); // Join multiple values with a comma
        return `${encodeURIComponent(key)}=${encodeURIComponent(values)}`;
      })
      .join('&');

    console.log('Query String:', queryString, filters); // Debugging output

    const res = await axiosClient.get(`/courses/client/?${queryString}`);

    return res.data;
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error; // Rethrow the error to handle it elsewhere if needed
  }
};


