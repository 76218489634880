import React from 'react'
import { Link } from 'react-router-dom'

export default function PaymentError() {
  return (
    <section>
      <div className='flex items-center justify-center h-screen flex-col'>
        <div className='p-1 rounded shadow-lg bg-gradient-to-r from-red-500 via-red-950 to-red-400'>
          <div className='flex flex-col items-center p-4 space-y-2 bg-alpha'>
            {/* Replace the checkmark SVG with a cross SVG */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='text-red-600 w-28 h-28'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth='2' // Change 'stroke-width' to 'strokeWidth'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
            </svg>
            <h1 className='text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500'>
              Payment Failed!
            </h1>
            <p>Something went wrong we couldn't process your payment.</p>
            <Link to='/'>
              <button className='p-4'>Back to home</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
