import Header from 'src/components/header'

export default function UserLayout({ children }) {
  return (
    <>
      <Header />
      <main>{children}</main>
    </>
  )
}
