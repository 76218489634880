import React from 'react'
import ThanksPaypal from 'src/components/ThanksPaypal'
import { GuardWrapper } from 'src/layouts/GuardWrapper'
import UserLayout from 'src/layouts/UserLayout'

function ThankYouPaypal(props) {
  return (
    <GuardWrapper {...props}>
      <div>
        <ThanksPaypal />
      </div>
    </GuardWrapper>
  )
}
ThankYouPaypal.defaultProps = {
  getLayout: page => <UserLayout>{page}</UserLayout>,
  guestGuard: false,
  authGuard: true
}

export default ThankYouPaypal
