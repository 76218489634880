import { axiosClient } from 'src/configs/axios'

export const getUserDataInfo = async () => {
  try {
    const res = await axiosClient.get(`/progress/my-course`)

    return res.data
  } catch (error) {
    throw error
  }
}
export const forgetPassword = async payload => {
  try {
    const res = await axiosClient.post(`/jwt/forget-password`, payload)

    return res
  } catch (error) {
    throw error
  }
}
export const resetPassword = async payload => {
  try {
    const res = await axiosClient.post(`/jwt/reset-password`, payload)

    return res
  } catch (error) {
    throw error
  }
}
