const { createSlice } = require('@reduxjs/toolkit')
const { getUserData } = require('./getUserData.action')

const initialState = {
  isUserUserGetting: false,
  isUserUserFetched: false,
  isUserUserFailedToFailed: false,
  user: { rank: null }
}

const User = createSlice({
  name: 'userUser',
  initialState: initialState,
  extraReducers: {
    [getUserData.pending]: state => {
      state.isUserUserGetting = true
    },
    [getUserData.fulfilled]: (state, action) => {
      state.isUserUserGetting = false
      state.isUserUserFetched = true
      state.user = action.payload
    },
    [getUserData.rejected]: state => {
      state.isUserUserGetting = false
      state.isUserUserFetched = false
      state.isUserUserFailedToFailed = true
    }
  }
})

export default User.reducer
