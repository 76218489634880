import { axiosClient } from 'src/configs/axios'

export const createPaymentAPI = async payload => {
  return axiosClient.post(`/payment/create-checkout-session`, payload)
}

export const getPaymentAPI = async payload => {
  return axiosClient.get(`/payment/get-checkout-session/${payload}`)
}
export const getPaypalPaymentAPI = async payload => {
  return axiosClient.get(`/payment/paypal/subscriber/${payload}`)
}

export const postPaypalPaymentAPI = async payload => {
  return axiosClient.post('/payment/paypal/save-subscription', payload)
}
