import React from 'react'

export default function ThanksPaypal() {
  return (
    <section className='flex items-center justify-center min-h-screen bg-alpha'>
      <div className='p-6 rounded-lg shadow-lg bg-white max-w-lg'>
        <div className='flex flex-col items-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='text-green-600 w-28 h-28'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth='1'
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
          </svg>
          <h1 className='text-4xl font-bold text-gray-800 mt-4'>Thank You!</h1>
          <p className='text-gray-600 mt-2 text-center'>
            Your subscription has been successfully activated. For more information, feel free to contact us.
          </p>
          <p className='text-blue-600 mt-4'>
            <a href='https://sanspareil.gg' target='_blank' rel='noopener noreferrer'>
              Sanspareil.gg
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}
