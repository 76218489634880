import { updateProfilePic } from './profilePic.action.js'

const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  isProfilePicUpdating: false,
  isProfilePicUpdated: false,
  isProfilePicFailedToupdate: false,
  displayImg: ''
}

const updateProfilePicSlicer = createSlice({
  name: 'updateProfilePic',
  initialState,
  extraReducers: {
    [updateProfilePic.pending]: state => {
      state.isProfilePicUpdating = true
    },
    [updateProfilePic.fulfilled]: (state, action) => {
      state.isProfilePicUpdating = false
      state.isProfilePicUpdated = true
      state.displayImg = action.payload?.displayImg
    },
    [updateProfilePic.rejected]: state => {
      state.isProfilePicUpdating = false
      state.isProfilePicUpdated = false
      state.isProfilePicFailedToupdate = true
    }
  }
})

export default updateProfilePicSlicer.reducer
