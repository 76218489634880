import { Icon } from '@iconify/react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import BlankLayout from 'src/layouts/BlankLayout'
import { GuardWrapper } from 'src/layouts/GuardWrapper'
import { forgetPasswordApi } from 'src/redux/userData/getUserData.action'

const ForgotPassword = props => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()

  const HandleSubmit = async e => {
    e.preventDefault()
    if (email) {
      await dispatch(forgetPasswordApi({ email: email }))
      setEmail('')
    }
  }

  return (
    <GuardWrapper {...props}>
      <section data-aos='zoom-in' data-aos-duration='1000'>
        <Link to='/' className='block mt-5 mx-8'>
          <Icon icon='bx:arrow-back' className='text-white hover:cursor-pointer text-2xl font-bold' />
        </Link>

        <div className='w-full h-screen flex flex-col justify-center items-center  text-white'>
          <div className='w-[90%] sm:w-[80] md:w-[60] lg:w-[35%] shadow-lg p-8 rounded-md bg-black   '>
            <div className='mb-8'>
              <h1 className='text-2xl font-bold'>Oh! Forgot your password?</h1>
              <p>No worries, enter your email and we will send you a reset link.</p>
            </div>
            <form className='flex flex-col space-y-4' onSubmit={HandleSubmit}>
              <input
                id='email'
                type='email'
                placeholder='Enter your email'
                className='w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none bg-[#232325] outline-none border-none'
                required
                onChange={e => setEmail(e.target.value)}
              />
              <button type='submit' className='w-full bg-white text-black py-2 rounded-md focus:outline-none'>
                Send me reset link
              </button>
            </form>
          </div>
        </div>
      </section>
    </GuardWrapper>
  )
}

ForgotPassword.defaultProps = {
  getLayout: page => <BlankLayout>{page}</BlankLayout>,
  guestGuard: true
}

export default ForgotPassword
