import iron from 'src/assets/images/Rank=Iron.png'
import bronze from 'src/assets/images/Rank=Bronze.png'
import silver from 'src/assets/images/Rank=Silver.png'
import gold from 'src/assets/images/Rank=Gold.png'
import platinum from 'src/assets/images/Rank=Platinum.png'
import diamond from 'src/assets/images/Rank=Diamond.png'
import grandmaster from 'src/assets/images/Rank=Grandmaster.png'
import challenger from 'src/assets/images/Rank=Challenger.png'
import emerald from 'src/assets/images/Rank=Emerald.png'
import master from 'src/assets/images/Rank=Master.png'

export const ranks = {
  Iron: iron,
  Bronze: bronze,
  Silver: silver,
  Gold: gold,
  Platinum: platinum,
  Emerald: emerald,
  Diamond: diamond,
  Master: master,
  Grandmaster: grandmaster,
  Challenger: challenger
}
