import { axiosClient } from 'src/configs/axios'

export const getCategories = async () => {
  try {
    const res = await axiosClient.get(`/categories/client`)

    return res.data
  } catch (error) {
    throw error
  }
}
