import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPaymentAPI, getPaypalPaymentAPI, postPaypalPaymentAPI } from './api'
import toast from 'react-hot-toast'

export const getPayment = createAsyncThunk('getPayment', async payload => {
  const res = await getPaymentAPI(payload)
  return res.data
})

export const postPaypalPayment = createAsyncThunk('postPaypalPayment', async payload => {
  console.log('done')
  const resultPromise = new Promise((resolve, reject) => {
    postPaypalPaymentAPI(payload)
      .then(res => {
        resolve(res.data)
      })
      .catch(({ response }) => {
        reject(response.data?.error || 'Something went wrong')
      })
  })

  toast.promise(resultPromise, {
    loading: 'Request Sending...',
    success: 'Data Saved',
    error: err => err
  })

  return await resultPromise
})

export const getPaypalPayment = createAsyncThunk('getPaypalPayment', async payload => {
  const res = await getPaypalPaymentAPI(payload)
  return res.data
})
