import { axiosClient } from 'src/configs/axios'

export const updateUserEmailApi = async payload => {
  const res = await axiosClient.put('/profile/update-email', payload)
  return res
}

export const updateUserPasswpordApi = async payload => {
  const res = await axiosClient.put('/profile/update-password', payload)
  return res
}
