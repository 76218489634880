import React from 'react'
import ReactDOM from 'react-dom/client'
import Main from './main'
import reportWebVitals from './reportWebVitals'
import 'src/styles/index.css'
import 'src/styles/components.css'
import 'src/iconify-bundle/icons-bundle-react'
import 'aos/dist/aos.css'
import { store } from './store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <Main />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
