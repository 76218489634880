import { createAsyncThunk } from '@reduxjs/toolkit'
import { forgetPassword, getUserDataInfo, resetPassword } from './api'
import toast from 'react-hot-toast'

export const getUserData = createAsyncThunk('getUserData', async () => {
  const res = await getUserDataInfo()

  return res?.myCourse
})

export const forgetPasswordApi = createAsyncThunk('forgetPassword/passwordForget', async payload => {
  const resultPromise = new Promise((resolve, reject) => {
    forgetPassword(payload)
      .then(res => {
        resolve(res.data)
      })
      .catch(({ response }) => {
        reject(response.data?.error || 'Something went wrong')
      })
  })

  toast.promise(resultPromise, {
    loading: 'Request Sending...',
    success: 'Reset Link Successfully Sent',
    error: err => err
  })

  return await resultPromise
})
export const resetPasswordApi = createAsyncThunk('resetPassword/passwordReset', async payload => {
  const resultPromise = new Promise((resolve, reject) => {
    resetPassword(payload)
      .then(res => {
        resolve(res.data)
      })
      .catch(({ response }) => {
        reject(response.data?.error || 'Something went wrong')
      })
  })

  toast.promise(resultPromise, {
    loading: 'Changing Password...',
    success: 'Password Changed Successfully',
    error: err => err
  })

  return await resultPromise
})
