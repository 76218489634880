import { getPayment, getPaypalPayment } from './payment.action'

const { createSlice } = require('@reduxjs/toolkit')

const initialState = {
  isPaymentUpdating: false,
  isPaymentUpdated: false,
  isPaymentFailedToUpdate: false,
  payment: []
}

const PaymentSlicer = createSlice({
  name: 'Payment',
  initialState: initialState,
  extraReducers: {
    [getPayment.pending]: state => {
      state.isPaymentUpdating = true
    },
    [getPayment.fulfilled]: (state, action) => {
      state.isPaymentUpdating = false
      state.isPaymentUpdated = true
      state.payment = action.payload
    },
    [getPayment.rejected]: state => {
      state.isPaymentUpdating = false
      state.isPaymentUpdated = false
      state.isPaymentFailedToUpdate = true
    },
    [getPaypalPayment.pending]: state => {
      state.isPaymentUpdating = true
    },
    [getPaypalPayment.fulfilled]: (state, action) => {
      state.isPaymentUpdating = false
      state.isPaymentUpdated = true
      state.payment = action.payload
    },
    [getPaypalPayment.rejected]: state => {
      state.isPaymentUpdating = false
      state.isPaymentUpdated = false
      state.isPaymentFailedToUpdate = true
    }
  }
})

export default PaymentSlicer.reducer
