// ** Component Imports
import AuthGuard from 'src/components/auth/AuthGuard'
import GuestGuard from 'src/components/auth/GuestGuard'
import UserLayout from 'src/layouts/UserLayout'

// ** Spinner Import
import Spinner from 'src/components/spinner'

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
  }
}

export const GuardWrapper = props => {
  const { children } = props
  // Variables
  const getLayout = props.getLayout ?? (page => <UserLayout>{page}</UserLayout>)
  const authGuard = props.authGuard ?? true
  const guestGuard = props.guestGuard ?? false

  return (
    <Guard authGuard={authGuard} guestGuard={guestGuard}>
      {getLayout(children)}
    </Guard>
  )
}
