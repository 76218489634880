import { axiosClient } from 'src/configs/axios'

export const getUserCoursesData = async payload => {
  try {
    const res = await axiosClient.get(`/progress/my-course`)

    return res.data
  } catch (error) {
    throw error
  }
}
