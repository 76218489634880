import { axiosClient } from 'src/configs/axios'

export const GetCreatorProfileAPI = async payload => {
  try {
    const res = await axiosClient.get(`/profile/client/admin-details/${payload}`)

    return res.data
  } catch (error) {
    throw error
  }
}
