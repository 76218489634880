import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateUserEmailApi, updateUserPasswpordApi } from './api'
import toast from 'react-hot-toast'

export const updateUserEmail = createAsyncThunk('updateUserEmail/UserEmail', async payload => {
  const resultPromise = new Promise((resolve, reject) => {
    updateUserEmailApi(payload)
      .then(res => {
        resolve(res.data)
      })
      .catch(({ response }) => {
        reject(response.data?.error || 'Something went wrong')
      })
  })

  toast.promise(resultPromise, {
    loading: 'Updating email',
    success: 'Updated email Successfully',
    error: err => err
  })

  return await resultPromise
})
//passwords upadte
export const updateUserPassword = createAsyncThunk('updateUserPassword/UserPassword', async payload => {
  const resultPromise = new Promise((resolve, reject) => {
    updateUserPasswpordApi(payload)
      .then(res => {
        resolve(res.data)
      })
      .catch(({ response }) => {
        reject(response.data?.error || 'Something went wrong')
      })
  })

  toast.promise(resultPromise, {
    loading: 'Updating Password',
    success: 'Updated password Successfully',
    error: err => err
  })

  return await resultPromise
})
